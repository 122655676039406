import { useEffect } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";

type Arguments<T extends FieldValues> = {
  debug?: boolean;
  form?: UseFormReturn<T>;
  isSubmitDisabled?: boolean;
};

const useFormDebug = <T extends FieldValues>({ debug = false, form, isSubmitDisabled }: Arguments<T>) => {
  useEffect(() => {
    if (debug) {
      /* eslint-disable no-console */
      console.log(`Dirty: ${form?.formState.isDirty ? "✔" : "✘"}`);
      console.log(`Submitting: ${form?.formState.isSubmitting ? "✔" : "✘"}`);
      console.log(`Valid: ${form?.formState.isValid ? "✔" : "✘"}`);
      console.log(`Submit Button Disabled: ${isSubmitDisabled ? "✔" : "✘"}`);
      const values = form?.getValues();
      if (values) {
        console.log("*** Start Values ***");
        Object.keys(values).forEach(key => {
          console.log(`${key}:`, values[key]);
        });
        console.log("*** End Values ***");
      }
      /* eslint-enable no-console */
    }
  }, [debug, form, form?.formState.isDirty, form?.formState.isSubmitting, form?.formState.isValid, isSubmitDisabled]);
};

export default useFormDebug;
