export enum AccountSectionEnum {
  FAQ = "faq",
  INFO = "info",
  LOGOUT = "logout",
  MENU = "menu",
  PACKAGE = "package",
  PRIVACY = "privacy",
  PRIVACY_ACCOUNT_DELETION = "privacy_account_deletion",
  PRIVACY_CHANGE_PASSWORD = "privacy_change_password",
  PRIVACY_COMMUNITY_NOTIFICATIONS = "privacy_community_notifications",
  PRIVACY_EMAIL_MARKETING = "privacy_email_marketing",
  PRIVACY_EMAIL_NOTIFICATIONS = "privacy_email_notifications",
  PRIVACY_MARKETING = "privacy_marketing",
  PRIVACY_TWO_FACTOR = "privacy_two_factor",
  PURCHASE_HOURS = "purchase_hours",
  REFER_A_COWORKER = "refer_a_coworker",
  REPORT_A_PROBLEM = "report_a_problem"
}

export enum AcuityFieldNameEnum {
  RECENT_INCIDENT = "recentIncident",
  DAY_TO_DAY_SUPPORT_NEEDED = "dayToDaySupportNeeded",
  LIFE_THREATENING_CONDITION_OR_MULTIPLE_CONDITIONS = "lifeThreateningCondition",
  LONG_TERM_CONDITION = "longTermCondition",
  MENTAL_HEALTH_SUPPORT_NEEDED = "mentalHealthSupportNeeded",
  MULTIPLE_PROBLEMS = "multipleProblems"
}

export enum BackOrNextEnum {
  BACK = "back",
  NEXT = "next"
}

export enum CarePlanSectionEnum {
  ARCHIVE = "archive",
  CARE_PLAN = "care_plan",
  CARE_TEAM = "care_team",
  DRIVE = "drive",
  MENU = "menu",
  PROFILE = "profile",
  TRACKERS = "trackers",
  WORKSHEET = "worksheet"
}

export enum ContentConditionSourceEnum {
  ALWAYS = "always",
  CARE_PROFILE = "care_profile",
  CONSULTS = "consults",
  JOURNEY = "journey",
  LEGAL_DOCUMENTS = "legal_documents",
  MEDICATION_TRACKER = "medication_tracker",
  PROVIDER_LIST = "provider_list"
}

export enum ExpertSelectTypeEnum {
  ANY = "ANY",
  PRIMARY = "PRIMARY"
}

export enum GoalStatus {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
  COMPLETE = "COMPLETE"
}

export enum InitialConsultFieldEnum {
  AGE = "age",
  CAREGIVER_TYPES = "caregiver_types",
  CLIENT_CULTURAL_CONSIDERATIONS = "client_cultural_considerations",
  CLIENT_IS_VETERAN = "client_is_veteran",
  CLIENT_LOCATION = "client_location",
  CLIENT_ROLE_RELATIONSHIP = "client_role_relationship",
  CLIENT_TIMEZONE = "client_timezone",
  COGNITIVE_STATUSES = "cognitive_statuses",
  CONCERNS = "concerns",
  CULTURAL_CONSIDERATIONS = "cultural_considerations",
  EMERGENCY_PLAN_ITEMS = "emergency_plan_items",
  EMOTIONAL_CONCERNS = "emotional_concerns",
  FALL_COUNT = "fall_count",
  FINANCIAL_MANAGER = "financial_manager",
  HEALTH_INSURANCE_TYPES = "health_insurance_types",
  INSURANCE_TYPES = "insurance_types",
  IS_VETERAN_GUARDIAN = "is_veteran_guardian",
  IS_VETERAN_PARTNER = "is_veteran_partner",
  IS_VETERAN_SELF = "is_veteran_self",
  LANGUAGE_CODE = "language_code",
  LEGAL_DPOA_FINANCES_STATUS = "legal_dpoa_finances_status",
  LEGAL_DPOA_HEALTH_CARE_STATUS = "legal_dpoa_health_care_status",
  LEGAL_LIVING_WILL_STATUS = "legal_living_will_status",
  LEGAL_TRUST_STATUS = "legal_trust_status",
  LEGAL_WILL_STATUS = "legal_will_status",
  LOCATION = "location",
  MARITAL_STATUS = "merital_status",
  MEDICATIONS_AFFORDABLE = "medications_affordable",
  NAME = "name",
  PROVIDER_EMOTIONAL_STATE = "provider_emotional_state",
  RECENT_HOSPITALIZATION = "recent_hospitalization",
  RELIGION = "religion",
  RESIDENCE_TYPE = "residence_type",
  STABILITY_SUPPORTS = "stability_supports",
  STAGES = "stages",
  STAIRS_REQUIRED_FOR_EXIT = "stairs_required_for_exit",
  TYPES_OF_SUPPORT_NEEDED = "types_of_support_needed"
}

export enum JourneyStatusEnum {
  ARCHIVED = "archived",
  CANCELED = "canceled",
  DRAFT = "draft",
  COMPLETED = "completed",
  IN_PROGRESS = "in_progress",
  NEW = "new",
  UNPUBLISHED_ITEMS = "unpublished_items",
  UPDATED = "updated",
  UPDATING = "updating",
  VIEWED = "viewed"
}

export enum LibraryTabEnum {
  EVENTS = "EVENTS",
  MEDIA = "MEDIA",
  PINS = "PINS"
}

export enum MessageTemplateMergeTagEnum {
  COMPANY_NAME = "{companyName}",
  EXPERT_FIRST_NAME = "{expertFirstName}",
  OWNER_MEMBER_FIRST_NAME = "{ownerMemberFirstName}"
}

export enum NeedsInterpretationEnum {
  ALL = "all",
  NEEDS_INTERPRETATION = "needs_interpretation"
}

export enum OnboardingStepEnum {
  ACUITY_CHRONIC = "acuityChronic",
  ACUITY_COMPLEX = "acuityComplex",
  ACUITY_DAILY = "acuityDaily",
  ACUITY_EPISODIC = "acuityEpisodic",
  ACUITY_MENTAL_HEALTH = "acuityMentalHealth",
  ACUITY_MULTIPLE = "acuityMultiple",
  CARE_DURATION = "careDuration",
  CARE_PHASE = "carePhase",
  CAREGIVER_STRESS = "caregiverStress",
  COMPLETE = "complete",
  HOUSEHOLD = "household",
  INTRO = "intro",
  LOCATION = "location",
  LOVED_ONE_AGE = "lovedOneAge",
  LOVED_ONE_NAME = "lovedOneName",
  NEEDS = "needs",
  NEEDS_FROM_GRAYCE = "needsFromGrayce",
  PAST_THIRTY_DAYS_EVENTS = "pastThirtyDaysEvents",
  PREGNANCY_HIGH_RISK = "pregnancyHighRisk",
  RELATIONSHIP_TO_LOVED_ONE = "relationshipToLovedOne",
  SMS_SIGNUP = "smsSignup",
  TIMEZONE = "timezone",
  USE_CASE_PREFERENCES = "useCasePreferences",
  USE_CASE_SELECTION = "useCaseSelection"
}

export enum OpenerTypeEnum {
  MESSAGES = "messages",
  TASKS = "tasks",
  TIME_LOG = "time_log",
  UNIVERSAL_SEARCH = "universal_search"
}

export enum OtherActivityKindsEnum {
  ALL = "all",
  ASSESSMENT_COMPLETED = "assessment_completed",
  CONTENT_VIEWED = "content_viewed"
}

export enum PersonalizeExperienceFieldEnum {
  PROVIDER_EMOTIONAL_STATE = "provider_emotional_state",
  PROVIDER_FEELS_SUPPORTED = "provider_feels_supported"
}

export enum ScrollPositionPageTypeEnum {
  CARE_PLAN = "care_plan",
  DOCUMENTS = "documents",
  GOAL_TEMPLATES = "goal_templates",
  HOME = "home",
  LEARN = "learn",
  MARKETPLACE = "marketplace",
  WORKSHEET_TEMPLATES = "worksheet_templates"
}

export enum SlideDirectionEnum {
  RIGHT = "right",
  LEFT = "left"
}

export enum SortByTimestampDirectionEnum {
  CHRONOLOGICAL = "chron",
  REVERSE_CHRONOLOGICAL = "reverseChron"
}

export enum StartTimeValuesEnum {
  ALL = "all",
  PAST_THREE_DAYS = "past_three_days",
  PAST_SEVEN_DAYS = "past_seven_days",
  PAST_THIRTY_DAYS = "past_thirty_days"
}

export enum TimePeriodEnum {
  ALL_TIME = "all_time",
  CUSTOM = "custom",
  NINETY_DAYS = "ninety_days",
  THIRTY_DAYS = "thirty_days",
  SEVEN_DAYS = "seven_days",
  SIXTY_DAYS = "sixty_days"
}

export enum TwoFactorSteps {
  SETUP_TYPE = "setup_type",
  VERIFY_CODE = "verify_code"
}

export enum UniversalSearchSortOptionEnum {
  SCORE = "SCORE",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  LAST_ACTIVITY_AT_ASC = "LAST_ACTIVITY_AT_ASC",
  LAST_ACTIVITY_AT_DESC = "LAST_ACTIVITY_AT_DESC"
}

export enum ModeEnum {
  HISTORY = "history",
  PROFILE = "profile"
}
