import { useIonModal } from "@ionic/react";
import { ReactNode, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import FormButtons from "@components/v1/fields/FormButtons";
import CircleButton from "@components/v2/buttons/CircleButton";
import Text from "@components/v2/typography/Text";
import { Opener } from "@hooks/useOpener";
import xmark from "@icons/solid/xmark.svg";

type Props = {
  cancelKey?: string;
  deleteKey?: string;
  extraContent?: ReactNode;
  headerKey?: string;
  messageKey?: string;
  messageValue?: string | null;
  messageValueKey?: string;
  onCancel?: () => void;
  onDelete: () => void;
  opener: Opener;
  skipCloseOnDelete?: boolean;
};

type ModalBodyProps = {
  cancelKey: string;
  deleteKey: string;
  extraContent?: ReactNode;
  headerKey: string;
  messageKey?: string;
  messageValue: string | null | undefined;
  messageValueKey: string | undefined;
  onCancel: () => void;
  onDelete: () => void;
};

const ModalBody = ({
  cancelKey,
  deleteKey,
  extraContent,
  headerKey,
  messageKey,
  messageValue,
  messageValueKey,
  onCancel,
  onDelete
}: ModalBodyProps) => (
  <>
    <div className="flex flex-row justify-end padding-8-top">
      <CircleButton
        ariaLabelKey="accessibility.ariaLabels.close"
        className="ion-margin-end"
        color="gray-300"
        data-test="close-modal"
        fill="clear"
        icon={xmark}
        onClick={onCancel}
        size="large"
        slot="end"
      />
    </div>
    <div className="flex flex-column gap-xl padding-20 items-center" data-test="delete-confirmation-alert">
      <Text bold color="gray" size="xl">
        <FormattedMessage id={headerKey} />
      </Text>

      <Text className="text-center" color="gray" size="md">
        {messageValueKey && messageValue ? (
          <FormattedMessage id={messageKey} values={{ [messageValueKey]: messageValue }} />
        ) : messageKey ? (
          <FormattedMessage id={messageKey} />
        ) : null}
      </Text>

      {extraContent}

      <div className="text-center ion-margin-bottom">
        <FormButtons
          cancelKey={cancelKey}
          narrowMargin
          onCancel={onCancel}
          onSave={onDelete}
          saveKey={deleteKey}
          skipValid
        />
      </div>
    </div>
  </>
);

const DeleteConfirmationAlert = (props: Props) => {
  const [deleteAfterDismiss, setDeleteAfterDismiss] = useState(false);

  const {
    cancelKey = "dictionary.cancel",
    deleteKey = "dictionary.delete",
    extraContent,
    headerKey = "dictionary.reallyDelete",
    messageKey,
    messageValue,
    messageValueKey,
    onCancel,
    onDelete,
    opener,
    skipCloseOnDelete = false
  } = props;

  const [presentModal, dismissModal] = useIonModal(ModalBody, {
    cancelKey,
    deleteKey,
    extraContent,
    headerKey,
    messageKey,
    messageValue,
    messageValueKey,
    onCancel: onCancel ?? opener.close,
    onDelete: () => {
      if (skipCloseOnDelete) {
        onDelete();
      } else {
        // When the opener is closed, the modal is removed asynchronously (i.e. not until the next
        // event loop). However, the onDelete() behavior happens in this event loop. In some
        // circumstances in which this component is used, onDelete() will cause the component that
        // holds the modal to unmount, which can cause the modal itself to become stuck on screen.
        // Thus, use a state variable to make onDelete() execute asynchronously. Note: it is not
        // enough to execute onDelete() in a 0 delay timeout or promise resolution. Even though that
        // is asynchronous, it would still be executed before modal removal in the next event loop.
        setDeleteAfterDismiss(true);
        opener.close();
      }
    }
  });

  useEffect(() => {
    if (opener.isOpen) {
      presentModal({
        backdropDismiss: false,
        cssClass: "fitToContent",
        onDidDismiss: opener.close
      });
    } else {
      dismissModal();
    }
  }, [dismissModal, opener.close, opener.isOpen, presentModal]);

  useEffect(() => {
    if (!opener.isOpen && deleteAfterDismiss) {
      setDeleteAfterDismiss(false);
      onDelete();
    }
  }, [opener.isOpen, deleteAfterDismiss, onDelete]);

  return null;
};

export default DeleteConfirmationAlert;
