import classnames from "classnames";
import { ComponentProps, forwardRef } from "react";
import { useIntl } from "react-intl";

import Button from "@components/v2/buttons/Button";
import styles from "@components/v2/buttons/CircleButton.module.css";
import IconBase from "@components/v2/icons/IconBase";
import { Colors } from "@utils/colorUtils";

type Props = Omit<ComponentProps<typeof Button>, "aria-label" | "children" | "resizeOnMobile" | "size" | "variant"> &
  Pick<ComponentProps<typeof IconBase>, "icon"> & {
    ariaLabelKey: string;
    color?: Colors;
    size?: "small" | "default" | "medium" | "large" | "menu";
  };

const CircleButton = forwardRef<HTMLIonButtonElement, Props>(
  ({ ariaLabelKey, className, icon, size = "default", ...buttonProps }, ref) => {
    const intl = useIntl();

    return (
      <Button
        {...buttonProps}
        aria-label={intl.formatMessage({ id: ariaLabelKey })}
        className={classnames("component-CircleButton", "ion-no-margin", styles.circleButton, styles[size], className)}
        ref={ref}
        resizeOnMobile={false}
      >
        <IconBase icon={icon} slot="icon-only" />
      </Button>
    );
  }
);

export default CircleButton;
