import { DatetimeChangeEventDetail } from "@ionic/core";
import { IonDatetime, IonPopover } from "@ionic/react";
import { Control, Controller } from "react-hook-form";
import { useIntl } from "react-intl";

import useOpener from "@hooks/useOpener";

type Props = {
  control: Control;
  error: any;
  id: string;
  labelKey: string;
  name: string;
  value: string;
};

const DateTimePopover = ({ control, error, id, labelKey, name, value }: Props) => {
  const dateOpener = useOpener();
  const intl = useIntl();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange } }) => (
        <IonPopover
          alignment="center"
          isOpen={dateOpener.isOpen}
          keepContentsMounted={true}
          onDidDismiss={dateOpener.close}
          showBackdrop={false}
          side="bottom"
        >
          <IonDatetime
            aria-describedby={error ? `${name}-or-error` : ""}
            aria-invalid={error?.message?.length ? "true" : "false"}
            aria-label={intl.formatMessage({ id: labelKey })}
            id={id}
            name={name}
            onIonChange={(event: CustomEvent<DatetimeChangeEventDetail>) => {
              let newValue = event.detail.value;
              if (newValue && typeof newValue === "string") {
                newValue = newValue.split("T")[0];
              }
              if (newValue !== value) {
                if (newValue) {
                  onChange(event.detail.value);
                } else {
                  onChange(null);
                }
              }
            }}
            presentation="date"
            showClearButton
            showDefaultButtons
            value={value}
          />
        </IonPopover>
      )}
    />
  );
};

export default DateTimePopover;
