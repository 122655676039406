import classnames from "classnames";
import { useMemo } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import styles from "@components/v1/fields/FormButtons.module.css";
import Button from "@components/v2/buttons/Button";
import IconBase from "@components/v2/icons/IconBase";
import useFormDebug from "@hooks/useFormDebug";
import spinner from "@icons/solid/spinner.svg";

type Props<FormData extends FieldValues> = {
  alignment?: "center" | "left" | "right";
  buttonSize?: "large";
  cancelKey?: string;
  debug?: boolean;
  expand?: "full" | "block";
  fullRow?: boolean;
  hookForm?: UseFormReturn<FormData>;
  narrowMargin?: boolean;
  onCancel?: () => void;
  onSave?: () => void;
  saveDisabled?: boolean;
  saveKey?: string;
  skipCancel?: boolean;
  skipDirty?: boolean;
  skipReset?: boolean;
  skipValid?: boolean;
  spinnerWhenSubmitting?: boolean;
  stackOnSmallScreen?: boolean;
};

const FormButtons = <FormData extends FieldValues>({
  alignment = "left",
  buttonSize,
  cancelKey = "dictionary.cancel",
  debug = false,
  expand,
  fullRow = false,
  hookForm,
  narrowMargin = false,
  onCancel,
  onSave,
  saveDisabled = false,
  saveKey = "dictionary.saveChanges",
  skipCancel,
  skipDirty = false,
  skipReset = false,
  skipValid = false,
  spinnerWhenSubmitting = false,
  stackOnSmallScreen = false
}: Props<FormData>) => {
  /*
    the reason to disable the exhaustive-deps rule on this hook is that the rule thinks that
    including `hookForm` in the dependencies array is enough to re-render the memo whenever for
    `formState` attributes of `hookForm` changes. however, this isn't actually true, so we need to
    include those attributes in the dependencies array as well.
  */
  const isSubmitDisabled = useMemo(
    () =>
      (!skipValid && hookForm && !hookForm.formState.isValid) ||
      (!skipDirty && hookForm && !hookForm.formState.isDirty) ||
      hookForm?.formState.isSubmitting ||
      saveDisabled,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      hookForm,
      hookForm?.formState.isDirty,
      hookForm?.formState.isSubmitting,
      hookForm?.formState.isValidating,
      hookForm?.formState.isValid,
      saveDisabled,
      skipValid
    ]
  );

  useFormDebug({ debug, form: hookForm, isSubmitDisabled });

  return (
    <div
      className={classnames(
        styles.footer,
        narrowMargin && styles.narrowMargin,
        fullRow && styles.flex,
        styles[`align-${alignment}`],
        stackOnSmallScreen && styles.stackOnSmallScreen
      )}
    >
      {!skipCancel && (
        <Button
          disabled={hookForm?.formState.isSubmitting ?? saveDisabled}
          expand={expand}
          fill="clear"
          onClick={() => {
            if (!skipReset && hookForm) {
              hookForm.reset();
            }
            onCancel?.();
          }}
          type="button"
        >
          <FormattedMessage id={cancelKey} />
        </Button>
      )}

      <Button
        data-test="submit-button"
        disabled={isSubmitDisabled}
        expand={expand}
        onClick={onSave}
        size={buttonSize}
        type="submit"
      >
        {hookForm?.formState.isSubmitting && spinnerWhenSubmitting && <IconBase icon={spinner} slot="start" spin />}
        <FormattedMessage id={saveKey} />
      </Button>
    </div>
  );
};

export default FormButtons;
