export const colorsArr = [
  "black",
  "danger",
  "danger-300",
  "danger-500",
  "dark",
  "eggplant",
  "facetime",
  "gray",
  "gray-25",
  "gray-50",
  "gray-50-shade",
  "gray-100",
  "gray-150",
  "gray-200",
  "gray-300",
  "gray-300-tint",
  "lighter",
  "light",
  "light-blue",
  "medium",
  "menu",
  "neutral",
  "neutral-base",
  "orange",
  "peach",
  "peach-900",
  "pink",
  "pink-900",
  "primary",
  "primary-50",
  "primary-100",
  "primary-300",
  "primary-300-shade",
  "primary-500",
  "robins-egg",
  "secondary",
  "secondary-50",
  "secondary-100",
  "secondary-300",
  "secondary-500",
  "secondary-900",
  "sky",
  "success",
  "success-500",
  "success-shade",
  "success-tint",
  "teal",
  "teal-50",
  "teal-400",
  "teal-500",
  "warning",
  "white",
  "white-pink",
  "yellow"
] as const;

export type Colors = (typeof colorsArr)[number];

export const lightenColor = (color: string, percent: number) => {
  const num = parseInt(color.replace("#", ""), 16);
  const amt = Math.round(2.55 * percent);
  const R = (num >> 16) + amt;
  const B = ((num >> 8) & 0x00ff) + amt;
  const G = (num & 0x0000ff) + amt;
  return (
    "#" +
    (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
      (G < 255 ? (G < 1 ? 0 : G) : 255)
    )
      .toString(16)
      .slice(1)
  );
};
