import { ComponentProps } from "react";

import Tooltip from "@components/v2/overlays/Tooltip";

type Props = Pick<
  ComponentProps<typeof Tooltip>,
  "arrowPosition" | "children" | "color" | "content" | "delay" | "expand" | "initialDirection"
> & {
  condition: boolean;
};

const ConditionalTooltip = ({ children, condition, ...tooltipProps }: Props) => {
  if (!condition) {
    return children({});
  }

  return <Tooltip {...tooltipProps}>{tooltipTriggerProps => children(tooltipTriggerProps)}</Tooltip>;
};

export default ConditionalTooltip;
