import { IonDatetime, IonDatetimeButton, IonModal } from "@ionic/react";
import classNames from "classnames";
import { ComponentProps, ReactNode, useMemo } from "react";
import { FieldError, FieldPath, FieldValues, Merge, Path, UseFormRegisterReturn } from "react-hook-form";

import DateTimePopover from "@components/v1/carePlan/DateTimePopover";
import Error from "@components/v1/fields/Error";
import styles from "@components/v1/fields/Input.module.css";
import RequiredFieldIndicator from "@components/v1/fields/RequiredFieldIndicator";
import SmallLabel from "@components/v1/labels/SmallLabel";
import InputLabel from "@components/v1/typography/InputLabel";
import { generateRegisterEvent } from "@utils/formUtils";
import { generateHTMLId } from "@utils/htmlUtils";

type Props<FormData extends FieldValues> = ComponentProps<typeof IonDatetime> & {
  asPopover?: boolean;
  control?: any;
  dateOnly?: boolean;
  error: Merge<FieldError, (FieldError | undefined)[]> | undefined;
  label?: string | ReactNode;
  name: FieldPath<FormData>;
  noSpacing?: boolean;
  register: UseFormRegisterReturn<Path<FormData>>;
  required?: boolean;
  subLabel?: string | ReactNode;
};

const DateTime = <FormData extends FieldValues>({
  asPopover = false,
  control,
  dateOnly = false,
  error,
  label,
  name,
  noSpacing = false,
  register,
  required,
  subLabel,
  value,
  ...datetimeProps
}: Props<FormData>) => {
  const className = styles.input + " " + (error ? styles.error : "");

  const id = generateHTMLId();

  const { onBlur, onChange } = register;

  const arrayErrors = useMemo(() => {
    if (error === undefined) {
      return undefined;
    }

    if (!Array.isArray(error)) {
      return [error];
    }
    return error;
  }, [error]);

  return (
    <>
      <div className={noSpacing ? "" : styles.wrapperItem}>
        {label && (
          <div>
            <InputLabel htmlFor={`datetime-${name}`}>
              {label}
              {required && <RequiredFieldIndicator />}
            </InputLabel>
          </div>
        )}
        {subLabel && (
          <div>
            <SmallLabel id={name}>
              {subLabel}
              {required && subLabel && !label && <RequiredFieldIndicator />}
            </SmallLabel>
          </div>
        )}
        <IonDatetimeButton
          className={classNames(styles.datetimeButton, noSpacing && styles.datetimeButtonNoSpacing)}
          datetime={id}
        />
      </div>
      {arrayErrors && arrayErrors.length > 0 && (
        <div id={`${name}-instructions-or-error`}>
          {arrayErrors?.map(error => <Error error={error} key={error?.message} />)}
        </div>
      )}
      {asPopover ? (
        <DateTimePopover
          control={control}
          error={error}
          id={id}
          labelKey="forms.task.dueTimeLabel"
          name={name}
          value={`${value}`}
        />
      ) : (
        <IonModal keepContentsMounted>
          <IonDatetime
            aria-describedby={error ? `${name}-instructions-or-error` : ""}
            aria-invalid={error?.message?.length ? "true" : "false"}
            aria-label={name}
            aria-required={required}
            className={value ? className + " " + styles.valueEntered : className}
            id={id}
            name={name}
            onIonBlur={event => {
              onBlur(event);
            }}
            onIonChange={event => {
              let newValue = event.detail.value;
              if (Array.isArray(newValue)) return; // we don't use this for multi-selects at the moment
              if (dateOnly && newValue) newValue = newValue.split("T")[0];
              if (newValue !== value) {
                onChange(generateRegisterEvent({ name, value: newValue }));
              }
            }}
            showDefaultButtons
            value={value ? value : undefined}
            {...datetimeProps}
          />
        </IonModal>
      )}
    </>
  );
};

export default DateTime;
